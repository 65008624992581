@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap");

$font-desc: Georgia, "Times New Roman", Times, serif;
$font-general: "Open Sans", sans-serif;
$font-monospace: "Inconsolata", monospace;

html {
  box-sizing: border-box;
}

* {
  font-family: $font-monospace;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body {
  height: 100vh;
  color: #282c34;
}

.logo {
  height: 45vmin;
  margin-top: 10vmin;
}

.content {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contact-content {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.description-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.desc-text {
  margin-bottom: 1.5em;
}

.title {
  font-size: calc(10px + 2vmin);
  letter-spacing: 0.1em;
  color: #282c34;
}

/* Navigation Bar */
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  height: 4vh;
  z-index: 100;
  align-items: center;
  padding: 3vh 0 2vh;
  transition: 0.6s;
}
.scrolling {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
}

.nav-links {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 50%;
  height: 30px;
  align-self: center;
}

.navlink-home {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  height: 30px;
  align-self: center;
}

a {
  color: rgb(77, 66, 231);
  text-decoration: none;
  &:hover {
    color: rgb(161, 91, 201);
  }
}

@mixin navlink {
  color: #282c34;
  &:hover {
    transition: 0.2s;
    color: rgb(161, 91, 201);
  }
}

.navlink-style {
  @include navlink;
  margin-top: 0.3rem;
}

.navlink-style-home {
  @include navlink;
  padding: 0;
  margin-bottom: 0;
  margin-left: 2rem;
  margin-right: 2rem;
}

@mixin nav-elems {
  text-align: center;
  z-index: 1;
  margin: 0 0 4px;
}

.home {
  @include nav-elems;
  justify-self: center;
  align-self: center;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 0;
  margin: 0;
}

.projects {
  @include nav-elems;
}
.experience {
  @include nav-elems;
}
.contact {
  @include nav-elems;
}

.active {
  margin-bottom: 0rem;
  color: rgb(161, 91, 201);
}

.burger {
  display: none;
  align-self: center;
  margin: 0 2em 0;
  cursor: pointer;

  div {
    transition: all 0.3s ease;
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px;
  }
}

.toggle {
  .line-1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .line-2 {
    opacity: 0;
  }

  .line-3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}

.description {
  // max-width: 55vmin;
  width: 60%;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  color: #282c34;
  // font-family: $font-desc;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  align-self: center;
}

.footer {
  height: 22vh;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  font-size: calc(5px + 2vmin);
  letter-spacing: 0.05em;
}

/* Smaller devices */
@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .home {
    color: white;
    &:hover {
      transition: 0.2s;
      color: rgb(161, 91, 201);
      border-bottom: none;
    }
  }
  .nav {
    background-color: rgba(196, 157, 219, 0.952);
  }
  .nav-links {
    position: absolute;
    top: 9vh;
    flex-direction: column;
    right: 0px;
    height: 91vh;
    background-color: rgba(196, 157, 219, 0.952);
    display: none;
    transition: transform 0.5s ease-in;
    width: 100%;
    div {
      opacity: 0;
    }
  }
  .navlink-style {
    p {
      color: white;
      font-weight: 700;
      &:hover {
        transition: 0.2s;
        color: rgb(161, 91, 201);
        border-bottom: none;
      }
    }
  }
  .nav-open {
    transform: translateX(0);
    display: flex;
  }
  .active {
    border: none;
    color: rgb(161, 91, 201);
  }
  .burger {
    display: block;
    &:hover {
      transition: 0.2s;
      div {
        background-color: rgb(161, 91, 201);
      }
    }
  }
}
