$primary-color: white;

.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(10, auto);
}
.project_box {
	max-width: 40vmax;
	height: auto;
	background-color: $primary-color;
	margin: 20px 5px 10px 5px;
}

.proj-image-link {
	cursor: pointer;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0;
	border-radius: 0.5em;
}

a.proj-image-link {
	&:hover,
	&:active {
		transition: 0.2s;
		// background-color:rgb(228, 198, 241);
		.proj-image {
			opacity: 0.1;
		}
		.proj-elem-details {
			visibility: visible;
		}
	}
}

.image-elem-container {
	display: flex;
	flex-direction: row;
	object-fit: cover;
	margin: 0;
}

.proj-image {
	max-width: 30vmax;
	height: 40vh;
	object-fit: cover;
	border-radius: 0.5em;
}

.proj-elem-details {
	position: absolute;
	visibility: hidden;
	align-self: center;
}

.proj-elem-desc {
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	line-height: 1.5em;
	color: black;
}

.tools {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.tool {
	margin: 2px;
	border-radius: 0.1em;
	border: solid blueviolet 1.2px;
	background-color: blueviolet;
}

.tool_text {
	color: white;
	font-size: 14px;
	margin: 0;
	padding: 4px 10px;
	font-weight: 500;
}

h2 {
	margin-top: 50px;
	margin-bottom: 30px;
}

@media (max-width: 1024px) {
	.grid {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
	}
	.proj-image {
		max-width: 35vmax;
	}
	.project_box {
		max-width: 45vmax;
	}
}

@media (max-width: 800px) {
	.proj-image {
		max-width: 35vmax;
	}
	.project_box {
		max-width: 45vmax;
	}
}
@media (max-width: 768px) {
	.grid {
		grid-template-columns: repeat(1, 1fr);
	}
	.proj-image {
		max-width: 50vmax;
	}
	.project_box {
		max-width: 60vmax;
	}
}
@media (max-width: 375px) {
	.proj-image {
		max-width: 45vmax;
	}
	.project_box {
		max-width: 55vmax;
	}
}

.project-title {
	margin: 0;
}

.part-subtitle {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
}

.project-content-container {
	display: flex;
	justify-content: center;
}
.project-content {
	display: flex;
	width: 80%;
	flex-direction: column;
	align-content: flex-end;
	justify-content: center;
	margin-top: 15vh;
}

// ProjectSubtitle

// PartContent

.part-content {
	// margin: 5vh 0 0;
	// background-color: red;
	margin: 0;
}

.project-desc {
	line-height: 2rem;
	font-size: 1rem;
	white-space: pre-line;
	text-align: justify;
	margin: 2vh 0 0;
}

.image-container {
	display: flex;
	flex-direction: column;
	margin: 0;
}

.image-content {
	width: 100%;
	height: auto;
}

// ProjectPart

.project-part {
	margin: 10vh 0;
}

.project-comp-title {
	margin-top: 0;
}

// PartTools

.part-tools-container {
	margin-top: 5vh;
}

.part-tools {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

// PartTool

.part-tool {
	background-color: blueviolet;
	margin: 2px 2px;
	border-radius: 2px;
	border: solid blueviolet 1.2px;
}

.part-tool-text {
	color: white;
	font-size: 16px;
	margin: 0;
	padding: 6px 14px;
	font-weight: 500;
}

.part-summary {
	display: flex;
	height: 400px;
	background-color: antiquewhite;
	margin: 15vh 4vh 4vh;
	justify-content: center;
}

.summary-header {
	font-weight: bold;
}

.part-sub-summary {
	display: flex;
	height: 300px;
	align-self: center;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 90%;
}

.summary-elem {
	margin: 0;

	// Project subtitle

	.sub {
		line-height: 1.5em;
	}

	@media (max-width: 420px) {
		.part-summary {
			height: 80vh;
		}
		.part-sub-summary {
			height: 90%;
			flex-direction: column;
			flex-wrap: nowrap;
			width: 95%;
		}
		.summary-elem {
			margin: 0 20px;
		}
	}
}
