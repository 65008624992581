@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

html {
  box-sizing: border-box; }

* {
  font-family: "Inconsolata", monospace; }
  *:before, *:after {
    box-sizing: inherit; }

body {
  height: 100vh;
  color: #282c34; }

.logo {
  height: 45vmin;
  margin-top: 10vmin; }

.content {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
  justify-content: center;
  text-align: center; }

.contact-content {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; }

.description-container {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.desc-text {
  margin-bottom: 1.5em; }

.title {
  font-size: calc(10px + 2vmin);
  letter-spacing: 0.1em;
  color: #282c34; }

/* Navigation Bar */
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: 4vh;
  z-index: 100;
  align-items: center;
  padding: 3vh 0 2vh;
  transition: 0.6s; }

.scrolling {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9); }

.nav-links {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 50%;
  height: 30px;
  align-self: center; }

.navlink-home {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  height: 30px;
  align-self: center; }

a {
  color: #4d42e7;
  text-decoration: none; }
  a:hover {
    color: #a15bc9; }

.navlink-style {
  color: #282c34;
  margin-top: 0.3rem; }
  .navlink-style:hover {
    transition: 0.2s;
    color: #a15bc9; }

.navlink-style-home {
  color: #282c34;
  padding: 0;
  margin-bottom: 0;
  margin-left: 2rem;
  margin-right: 2rem; }
  .navlink-style-home:hover {
    transition: 0.2s;
    color: #a15bc9; }

.home {
  text-align: center;
  z-index: 1;
  margin: 0 0 4px;
  justify-self: center;
  align-self: center;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 0;
  margin: 0; }

.projects {
  text-align: center;
  z-index: 1;
  margin: 0 0 4px; }

.experience {
  text-align: center;
  z-index: 1;
  margin: 0 0 4px; }

.contact {
  text-align: center;
  z-index: 1;
  margin: 0 0 4px; }

.active {
  margin-bottom: 0rem;
  color: #a15bc9; }

.burger {
  display: none;
  align-self: center;
  margin: 0 2em 0;
  cursor: pointer; }
  .burger div {
    transition: all 0.3s ease;
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px; }

.toggle .line-1 {
  transform: rotate(-45deg) translate(-5px, 6px); }

.toggle .line-2 {
  opacity: 0; }

.toggle .line-3 {
  transform: rotate(45deg) translate(-5px, -6px); }

.description {
  width: 60%;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  color: #282c34;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  align-self: center; }

.footer {
  height: 22vh;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  font-size: calc(5px + 2vmin);
  letter-spacing: 0.05em; }

/* Smaller devices */
@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden; }
  .home {
    color: white; }
    .home:hover {
      transition: 0.2s;
      color: #a15bc9;
      border-bottom: none; }
  .nav {
    background-color: rgba(196, 157, 219, 0.952); }
  .nav-links {
    position: absolute;
    top: 9vh;
    flex-direction: column;
    right: 0px;
    height: 91vh;
    background-color: rgba(196, 157, 219, 0.952);
    display: none;
    transition: transform 0.5s ease-in;
    width: 100%; }
    .nav-links div {
      opacity: 0; }
  .navlink-style p {
    color: white;
    font-weight: 700; }
    .navlink-style p:hover {
      transition: 0.2s;
      color: #a15bc9;
      border-bottom: none; }
  .nav-open {
    transform: translateX(0);
    display: flex; }
  .active {
    border: none;
    color: #a15bc9; }
  .burger {
    display: block; }
    .burger:hover {
      transition: 0.2s; }
      .burger:hover div {
        background-color: #a15bc9; } }

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, auto); }

.project_box {
  max-width: 40vmax;
  height: auto;
  background-color: white;
  margin: 20px 5px 10px 5px; }

.proj-image-link {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  border-radius: 0.5em; }

a.proj-image-link:hover, a.proj-image-link:active {
  transition: 0.2s; }
  a.proj-image-link:hover .proj-image, a.proj-image-link:active .proj-image {
    opacity: 0.1; }
  a.proj-image-link:hover .proj-elem-details, a.proj-image-link:active .proj-elem-details {
    visibility: visible; }

.image-elem-container {
  display: flex;
  flex-direction: row;
  object-fit: cover;
  margin: 0; }

.proj-image {
  max-width: 30vmax;
  height: 40vh;
  object-fit: cover;
  border-radius: 0.5em; }

.proj-elem-details {
  position: absolute;
  visibility: hidden;
  align-self: center; }

.proj-elem-desc {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.5em;
  color: black; }

.tools {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.tool {
  margin: 2px;
  border-radius: 0.1em;
  border: solid blueviolet 1.2px;
  background-color: blueviolet; }

.tool_text {
  color: white;
  font-size: 14px;
  margin: 0;
  padding: 4px 10px;
  font-weight: 500; }

h2 {
  margin-top: 50px;
  margin-bottom: 30px; }

@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto; }
  .proj-image {
    max-width: 35vmax; }
  .project_box {
    max-width: 45vmax; } }

@media (max-width: 800px) {
  .proj-image {
    max-width: 35vmax; }
  .project_box {
    max-width: 45vmax; } }

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(1, 1fr); }
  .proj-image {
    max-width: 50vmax; }
  .project_box {
    max-width: 60vmax; } }

@media (max-width: 375px) {
  .proj-image {
    max-width: 45vmax; }
  .project_box {
    max-width: 55vmax; } }

.project-title {
  margin: 0; }

.part-subtitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0; }

.project-content-container {
  display: flex;
  justify-content: center; }

.project-content {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  margin-top: 15vh; }

.part-content {
  margin: 0; }

.project-desc {
  line-height: 2rem;
  font-size: 1rem;
  white-space: pre-line;
  text-align: justify;
  margin: 2vh 0 0; }

.image-container {
  display: flex;
  flex-direction: column;
  margin: 0; }

.image-content {
  width: 100%;
  height: auto; }

.project-part {
  margin: 10vh 0; }

.project-comp-title {
  margin-top: 0; }

.part-tools-container {
  margin-top: 5vh; }

.part-tools {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.part-tool {
  background-color: blueviolet;
  margin: 2px 2px;
  border-radius: 2px;
  border: solid blueviolet 1.2px; }

.part-tool-text {
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 6px 14px;
  font-weight: 500; }

.part-summary {
  display: flex;
  height: 400px;
  background-color: antiquewhite;
  margin: 15vh 4vh 4vh;
  justify-content: center; }

.summary-header {
  font-weight: bold; }

.part-sub-summary {
  display: flex;
  height: 300px;
  align-self: center;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%; }

.summary-elem {
  margin: 0; }
  .summary-elem .sub {
    line-height: 1.5em; }
  @media (max-width: 420px) {
    .summary-elem .part-summary {
      height: 80vh; }
    .summary-elem .part-sub-summary {
      height: 90%;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 95%; }
    .summary-elem .summary-elem {
      margin: 0 20px; } }

